import { Modal } from 'antd';
import { FC } from 'react';
import giftCardPana from 'assets/images/gift-card-pana.svg';
import { useHistory } from 'react-router-dom';
import * as texts from 'assets/texts/coupon';

interface CouponModalProps {
  visible: boolean;
  onCancel: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
  couponId: string;
}

const CouponModal: FC<CouponModalProps> = (props) => {
  const history = useHistory();
  const { visible, onCancel, couponId } = props;

  const onOk = () => history.push(`/coupon/${couponId}`);

  return (
    <Modal
      title={<h2 style={{ margin: 0 }}>{texts.modalTitle}</h2>}
      visible={visible}
      onCancel={onCancel}
      cancelButtonProps={{ type: 'text' }}
      onOk={onOk}
      cancelText="Cerrar"
      okText="Ver cupón"
    >
      <img src={giftCardPana} alt="gift-card" />
      <p style={{ marginBottom: 0, textAlign: 'center' }}>{texts.modalBody}</p>
    </Modal>
  );
};

export default CouponModal;
