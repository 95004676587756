import { Row, Col, Button } from 'antd';
import React, { FC } from 'react';
import highFive from 'assets/images/highFive.svg';
import * as texts from '../../assets/texts/home';

const Home: FC = () => (
  <Row style={{ width: '90%', margin: 'auto' }}>
    <Col span={24} md={12}>
      <h1>{texts.title}</h1>
      <h3>{texts.subTitle}</h3>
      <h4 style={{ textAlign: 'center' }}>{texts.body}</h4>
      <img style={{ margin: '2vh auto' }} src={highFive} alt="¡High five!" />
      <Button
        style={{ margin: '3vh auto' }}
        type="primary"
        href="scan-check"
      >
        <h5>{texts.getStamp}</h5>
      </Button>
    </Col>
  </Row>
);
export default Home;
