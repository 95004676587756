import Home from 'views/Home';
import { FunctionComponent } from 'react';
import SignUp from 'views/SignUp';
// import LogIn from 'views/LogIn';
import Card from 'views/Card';
import Coupon from 'views/Coupon';
import Coupons from 'views/Coupons';
import Cards from 'views/Cards';
import Check from 'views/Check';
import QRCode from 'views/QRCode';
import ScanCheck from 'views/ScanCheck';
import Menu from 'views/Menu';
import VerifyMagicLink from 'views/VerifyMagicLink';

export type Route = {
  title: string;
  path: string;
  component: FunctionComponent;
  exact: boolean;
  private: boolean;
};

const routes: Route[] = [
  {
    title: 'Home',
    path: '/',
    component: Home,
    exact: true,
    private: true,
  },
  {
    title: 'Sign Up',
    path: '/sign-up',
    component: SignUp,
    exact: true,
    private: false,
  },
  {
    title: 'Log In',
    path: '/log-in',
    component: SignUp,
    exact: true,
    private: false,
  },
  {
    title: 'Verify',
    path: '/verify-link',
    component: VerifyMagicLink,
    exact: true,
    private: false,
  },
  {
    title: 'Menu',
    path: '/menu/:companyId',
    component: Menu,
    exact: true,
    private: false,
  },
  {
    title: 'Scan Check',
    path: '/scan-check',
    component: ScanCheck,
    exact: true,
    private: true,
  },
  {
    title: 'QR Read',
    path: '/card/:codeId',
    component: QRCode,
    exact: true,
    private: true,
  },
  {
    title: 'QR Read',
    path: '/qr-code/:codeId',
    component: QRCode,
    exact: true,
    private: true,
  },
  {
    title: 'Card',
    path: '/cards/:cardId',
    component: Card,
    exact: true,
    private: true,
  },
  {
    title: 'Cards',
    path: '/cards',
    component: Cards,
    exact: true,
    private: true,
  },
  {
    title: 'Check',
    path: '/check/:checkId',
    component: Check,
    exact: true,
    private: true,
  },
  {
    title: 'Coupon',
    path: '/coupon/:id',
    component: Coupon,
    exact: true,
    private: true,
  },
  {
    title: 'Coupons',
    path: '/coupons',
    component: Coupons,
    exact: true,
    private: true,
  },
];
export default routes;
