import { SmileOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { FC } from 'react';
import * as texts from 'assets/texts/coupon';
import styles from './styles.module.scss';

interface Props {
  prize: string;
  companyName: string;
  usedAt: string | null | undefined;
  clickable?: boolean;
}

const CouponTicket: FC<Props> = (props) => {
  const {
    prize, companyName, usedAt, clickable,
  } = props;
  let className = styles.TicketRow;
  if (usedAt) {
    className += ` ${styles.DisabledTicketRow}`;
  }
  if (clickable) {
    className += ` ${styles.Clickable}`;
  }

  return (
    <Row className={className}>
      <Col span={18}>
        <h2>{companyName}</h2>
        <h4>
          {usedAt ? texts.usedTicket(prize) : texts.ticket(prize)}
        </h4>
        {usedAt && <p>{texts.used(usedAt)}</p>}
      </Col>
      <Col className={styles.IconCol} span={6}>
        <SmileOutlined />
      </Col>
    </Row>
  );
};

CouponTicket.defaultProps = {
  clickable: true,
};

export default CouponTicket;
