import {
  ApolloClient, from, NormalizedCacheObject, Observable,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { SchemaLink } from '@apollo/client/link/schema';
// import { addMocksToSchema } from "@graphql-tools/mock";
import config from 'config';
import schema from 'apollo/schema';
import cache, { useMocksCache } from 'apollo/cache';
// import mocks from 'apollo/mocks';
import { CustomError } from 'apollo/types';
import { GraphQLError } from 'graphql';
import { getMainDefinition } from '@apollo/client/utilities';
import { getStoredUserAuth } from 'auth/storage';
import ErrorHandler from './ErrorHandler';

const { apiUrl, useMocks, apiKey } = config;
interface CustomGraphQLError extends GraphQLError {
  errorType?: string;
}

const errorLink = onError(({
  operation, forward, graphQLErrors, networkError,
}) => {
  const { query } = operation;
  const definition = getMainDefinition(query);
  if (graphQLErrors) {
    graphQLErrors.forEach((gqlError: CustomGraphQLError) => {
      const wrappedError: CustomError = {
        type: definition.kind === 'OperationDefinition' ? definition.operation : 'FATAL',
        code: gqlError.errorType || 'UNKNOWN',
        message: `${gqlError.message}${
          gqlError.extensions && gqlError.extensions.name
            ? ` - ${gqlError.extensions.name as string}`
            : ''
        }`,
      };
      ErrorHandler(wrappedError);
    });
    // Uncomment next line if the desired behavior is to retry the operation
    // return Observable.of(operation);
  }
  if (networkError) {
    const wrappedError: CustomError = {
      type: 'FATAL',
      code: 'NETWORK',
      message: networkError.message,
    };
    ErrorHandler(wrappedError);
    return Observable.of(operation);
  }
  return forward(operation);
});

const authLink = setContext((_, { headers }) => {
  const token = getStoredUserAuth().idToken;
  if (token) {
    return { headers: { ...headers, authorization: `${token}` } };
  }
  return { headers: { ...headers, 'x-api-key': apiKey } };
});

// const schemaWithMocks = addMocksToSchema({
//   schema,
//   mocks,
// });

const schemaWithMocks = schema;

const schemaLink = new SchemaLink({ schema: schemaWithMocks });

const apolloClient = new ApolloClient({
  link: from([
    authLink,
    errorLink,
    createUploadLink({
      uri: apiUrl,
    }),
  ]),
  cache,
});

export const mockClient = new ApolloClient({
  ssrMode: typeof window === 'undefined',
  cache,
  link: from([errorLink, schemaLink]),
});

const selectApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  if (useMocks) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMocksCache(true);
    return mockClient;
  }
  return apolloClient;
};

export default selectApolloClient;
