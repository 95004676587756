import React, { FC } from 'react';
import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  CoffeeOutlined, SmileFilled, SmileOutlined, SmileTwoTone,
} from '@ant-design/icons';
import { Col, Row } from 'antd';
import styles from './Checks.module.scss';

interface Props {
  repetitions: number;
  checks: unknown[];
  icon: string;
  iconSize?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Checks: FC<Props> = (props: Props) => {
  const {
    repetitions, checks, icon, iconSize,
  } = props;

  // // COLORS
  // $primary: #0d8aa6;
  // $pale-primary: #f2f2f2;
  // $dark-primary: #07668c;
  // $primary-font: #053742ff;
  // $secondary: #f2a413;
  // $pale-secondary: #d97a07;
  // $background: white;
  // $secondary-pink: #a63f03;

  return (
    <Row className={styles.Coupon}>
      <Col style={{ width: '100%' }}>
        {Array.apply('', Array(repetitions)).map((v, index) => {
          // const Comp = checks.length > index ? SmileTwoTone : SmileOutlined;
          // const color = checks.length > index ? '#f2a413' : '#053742aa';
          const Icon = icon === 'COFFEE' ? CoffeeOutlined : SmileOutlined;
          const color = checks.length > index ? '#f2a413' : '#05374255';

          return (
            <Icon
            // eslint-disable-next-line react/no-array-index-key
              key={index}
              twoToneColor={color}
              style={{
                fontSize: iconSize,
                margin: '3%',
                color,
              }}
            />
          );
        })}
      </Col>
    </Row>
  );
};

Checks.defaultProps = { iconSize: '40px' };

export default Checks;
