import { gql, useMutation } from '@apollo/client';
import { Check, AssignCheckInput } from 'apollo/generated/types';

const ASSIGN_CHECK = gql`
  mutation assignCheck(
    $checkId: String!
    $comment: String
  ) {
    assignCheck(
      input: {
        checkId: $checkId
        comment: $comment
      }
    ) {
      id
      card {
        id
      }
    }
  }
`;

const useAssignCheckMutation = () => useMutation<
  { assignCheck: Check }, AssignCheckInput
>(ASSIGN_CHECK);

export default useAssignCheckMutation;
