import React, { FC, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, notification } from 'antd';
import useAssignCheckMutation from 'apollo/resolvers/check/assign';
import LoadingModal from 'components/generalUI/LoadingModal';

interface QueryParams {
  checkId: string;
}

const Check: FC = () => {
  const history = useHistory();
  const { checkId } = useParams<QueryParams>();
  const [assignCheck, { data, loading, error }] = useAssignCheckMutation();
  useEffect(() => {
    assignCheck({ variables: { checkId } });
  }, [assignCheck, checkId]);
  useEffect(() => {
    if (data) {
      // Interpreting that since check was successfully assigned, it will have a card
      const cardId = data.assignCheck.card?.id;
      notification.success({
        message: '¡Tarjeta marcada con éxito!',
        duration: 10,
      });
      history.replace(`/cards/${cardId}`);
    }
  }, [data, history]);
  useEffect(() => {
    if (error) {
      history.replace('/scan-check');
    }
  }, [error, history]);
  return (
    <Row style={{ width: '90%', margin: 'auto' }}>
      <Col span={24} md={12}>
        <LoadingModal visible={loading} />
      </Col>
    </Row>
  );
};
export default Check;
