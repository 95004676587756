import { CoffeeOutlined, SmileOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { FC } from 'react';
import * as texts from 'assets/texts/card';
import getActiveCheksNumber from 'utils/card/getActiveCheksNumber';
import styles from './styles.module.scss';

interface Props {
  checks: number;
  repetitions: number;
  prize: string;
  companyName: string;
  icon: string;
}

const CardTicket: FC<Props> = (props) => {
  const {
    checks, repetitions, prize, companyName, icon,
  } = props;
  const Icon = icon === 'COFFEE' ? CoffeeOutlined : SmileOutlined;

  const activeChecks = getActiveCheksNumber(checks, repetitions);

  return (
    <Row className={styles.TicketRow}>
      <Col span={18}>
        <h2>{companyName}</h2>
        <h4>
          {texts.ticket(repetitions, prize)}
        </h4>
      </Col>
      <Col className={styles.IconCol} span={6}>
        <Icon />
        <h3>{texts.checksProgress(activeChecks, repetitions)}</h3>
      </Col>
    </Row>
  );
};

export default CardTicket;
