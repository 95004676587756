import Checks from 'components/card/Checks';
import LoadingModal from 'components/generalUI/LoadingModal';
import { FC, useEffect, useState } from 'react';
import { Row, Col, Button } from 'antd';
import { useParams } from 'react-router-dom';
import useGetCardQuery from 'apollo/resolvers/card/get';
import getCardNumber from 'utils/card/getCardNumber';
import getActiveCheksNumber from 'utils/card/getActiveCheksNumber';
import CouponModal from 'components/coupon/CouponModal';

interface QueryParams {
  cardId: string;
}

const Card: FC = () => {
  const { cardId } = useParams<QueryParams>();
  const { data, loading } = useGetCardQuery(cardId);
  const card = data?.getCard;
  const icon = card?.cardTemplate.icon;
  const prize = card?.cardTemplate.prize;
  const repetitions = card?.cardTemplate.repetitions;
  const companyName = card?.cardTemplate.company.name;
  const cardNumber = getCardNumber(card?.checks.length, repetitions);
  const activeChecksNumber = getActiveCheksNumber(card?.checks.length, repetitions);
  const activeChecks = activeChecksNumber
    && card?.checks.slice(-activeChecksNumber);
  const activeCoupon = card?.coupons?.find((coupon) => !coupon.usedAt);

  const [couponModalVisible, setCouponModalVisible] = useState(true);
  const onModalClose = () => setCouponModalVisible(false);
  useEffect(() => {
    if (activeCoupon) {
      setCouponModalVisible(true);
    } else {
      setCouponModalVisible(false);
    }
  }, [activeCoupon]);

  return (
    <Row style={{ width: '90%', margin: 'auto' }}>
      {repetitions && icon // used in condition for type safety
        ? (
          <Col style={{ width: '100%' }} span={24} md={12}>
            <CouponModal
              visible={couponModalVisible}
              onCancel={onModalClose}
              couponId={activeCoupon?.id as string}
            />
            <h1>{companyName}</h1>
            <h2>
              Mi tarjeta #
              {cardNumber}
            </h2>
            <Checks repetitions={repetitions} checks={activeChecks || []} icon={icon} />
            <h4>
              ¡Junta
              {` ${repetitions}`}
              {' '}
              marcas en tu tarjeta y obtén
              {` ${prize}`}
              !
            </h4>
            <Button href="/scan-check" type="primary" style={{ margin: '1vh' }}>
              <h5>Marcar mi tarjeta</h5>
            </Button>
          </Col>
        )
        : <LoadingModal visible={loading} />}
    </Row>
  );
};
export default Card;
