import { gql, useQuery } from '@apollo/client';
import { Coupon } from 'apollo/generated/types';

const LIST_COUPONS = gql`
  query listMyCoupons {
    listMyCoupons {
      id
      prize
      createdAt
      usedAt
      card {
        id
        cardTemplate {
          icon
          company {
            name
          }
      }
      }
    }
  }
`;

const useListCouponsQuery = () => useQuery<
{ listMyCoupons: Coupon[] }, void
>(LIST_COUPONS, { });
export default useListCouponsQuery;
