import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Company, QueryGetCompanyArgs } from 'apollo/generated/types';

const GET_COMPANY = gql`
  query getCompany($id: String!) {
    getCompany(id: $id) {
      id
      name
      menuUrl
      logoUrl
      cardTemplates {
        prize
        repetitions
        icon
        QRCode {
          id
        }
      }
    }
  }
`;

export const useGetCompanyQuery = (id: string) => useQuery<
{ getCompany: Company }, QueryGetCompanyArgs
>(GET_COMPANY, { variables: { id } });

export const useGetCompanyLazyQuery = (id: string) => useLazyQuery<
{ getCompany: Company }, QueryGetCompanyArgs
>(GET_COMPANY, { variables: { id } });
