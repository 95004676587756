import { gql, useQuery } from '@apollo/client';
import { Card } from 'apollo/generated/types';

const LIST_COUPONS = gql`
  query listMyCards {
    listMyCards {
      id
      cardTemplate {
        prize
        repetitions
        icon
        company {
          name
        }
        QRCode {
          id
        }
      }
      checks {
        createdAt
      }
    }
  }
`;

const useListCardsQuery = () => useQuery<
{ listMyCards: Card[] }, void
>(LIST_COUPONS, { });
export default useListCardsQuery;
