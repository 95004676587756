/* eslint-disable max-len */
export const titleSignUp = 'Regístrate';
export const titleSignIn = 'Ingresa a Volbi';
export const emailLabel = 'Email';
export const emailWarning = 'Por favor ingresa un email válido';
export const passwordLabel = 'Contraseña';
export const passwordWarning = 'La contraseña debe tener al menos 6 caractéres.';
export const passwordConfirmLabel = 'Confirma tu contraseña';
export const passwordConfirmWarning = 'Por favor confirma tu contraseña';
export const passwordConfirmMismatchWarning = 'Las contraseñas ingresadas no coinciden';
export const nameLabel = 'Nombre';
export const nameWarning = 'Por favor ingresa tu Nombre';
export const familyNameLabel = 'Apellido';
export const familyNameWarning = 'Por favor ingresa tu Apellido';
export const signUpLabel = '¿Aún no tienes cuenta?';
export const logInLabel = '¿Ya tienes cuenta? ';
export const signUpButton = 'Regístrate';
export const logInButton = '¡Ingresa!';
export const successfullSignUp = '¡Perfecto! Ya enviamos el link';
export const successfullSignUpsubtitle = (email: string) => `Revisa la bandeja de entrada de ${email}, haz click en el link y podrás ingresar a Volbi.`;
export const successfullSignUpProblemsButton = '¿Problemas? Hablemos ahora';
