import React, { FC, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col } from 'antd';
import LoadingModal from 'components/generalUI/LoadingModal';
import useGetOrCreateCardQuery from 'apollo/resolvers/card/getOrCreate';

interface QueryParams {
  codeId: string;
}

const QRCode: FC = () => {
  const history = useHistory();
  const { codeId } = useParams<QueryParams>();
  const { data, loading, error } = useGetOrCreateCardQuery(codeId);
  useEffect(() => {
    if (data) {
      const cardId = data.getOrCreateCard.id;
      history.replace(`/cards/${cardId}`);
    }
  }, [data, history]);
  useEffect(() => {
    if (error) {
      history.replace('/scan-check');
    }
  }, [error, history]);
  return (
    <Row style={{ width: '90%', margin: 'auto' }}>
      <Col span={24} md={12}>
        <LoadingModal visible={loading} />
      </Col>
    </Row>
  );
};
export default QRCode;
