import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Result } from 'antd';
import answerCustomChallenge from 'auth/methods/answerCustomChallenge';
import LoadingModal from 'components/generalUI/LoadingModal';
import * as texts from 'assets/texts/verifyMagicLink';

import styles from './VerifyMaginLink.module.scss';

const VerifyMagicLink = () => {
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const [rawEmail, answer, callbackUrl] = [
      params.get('email') || '',
      params.get('code') || '',
      params.get('callbackUrl') || '/',
    ];
    const email = rawEmail.replace(/ /g, '+');

    const onSuccess = () => {
      setIsLoading(false);
      history.replace(callbackUrl);
    };
    const onError = () => {
      setIsLoading(false);
      setIsError(true);
    };

    const getLoginResult = async () => {
      await answerCustomChallenge(
        email,
        answer,
        onSuccess,
        () => undefined,
        onError,
      );
    };

    getLoginResult();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.MainContainer}>
      <LoadingModal visible={isLoading} />
      {isError && (
        <Result
          status="error"
          title={texts.title}
          subTitle={texts.subTitle}
          extra={[
            <Button type="primary" key="console" onClick={() => { history.replace('/'); }}>
              {texts.retryButton}
            </Button>,
            <Button key="buy">
              <a href="tel:+56975431056">{texts.helpButton}</a>
            </Button>,
          ]}
        />
      )}
    </div>
  );
};

export default VerifyMagicLink;
