/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Col, Row } from 'antd';
import useListCouponsQuery from 'apollo/resolvers/coupon/list';
import CouponTicket from 'components/coupon/CouponTicket';
import LoadingModal from 'components/generalUI/LoadingModal';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import * as texts from 'assets/texts/coupon';
import EmptyResults from 'components/generalUI/EmptyResults';

const Coupons: FC = () => {
  const { data, loading } = useListCouponsQuery();
  const history = useHistory();

  return (
    <Row style={{ width: '90%', margin: 'auto' }}>
      <Col span={24} md={12}>
        <h1>{texts.listTitle}</h1>
        { data?.listMyCoupons && data.listMyCoupons.length === 0
          ? (
            <EmptyResults />
          )
          : (data?.listMyCoupons.map((coupon) => {
            const companyName = coupon.card.cardTemplate.company.name;
            const { prize, usedAt } = coupon;
            return (
              <div onClick={() => { history.push(`/coupon/${coupon.id}`); }} key={coupon.id}>
                <CouponTicket prize={prize} companyName={companyName} usedAt={usedAt} />
              </div>
            );
          }))}
        <LoadingModal visible={loading} />
      </Col>
    </Row>
  );
};

export default Coupons;
