/* eslint-disable @typescript-eslint/no-floating-promises */
import { useState } from 'react';
import { message as messageAntd } from 'antd';

const useErrorHandler = (err: Error | null) => {
  const [error, setError] = useState(err || null);
  const showError = (errorInput: Error | null) => {
    setError(errorInput);
    messageAntd.error(errorInput?.message, 10);
  };
  return { error, showError };
};
export default useErrorHandler;
