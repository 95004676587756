import React, { FC } from 'react';
import ReviLogo from 'assets/images/logo.png';
import { Col, Row } from 'antd';
import styles from './GuestNavBar.module.scss';

const GuestNavBar: FC = () => (
  <div>
    <Row justify="space-around" className={styles.Container}>
      <Col xs={{ span: 8 }} span={12}>
        <a href="/">
          <img src={ReviLogo} alt="Volbi Logo" className={styles.Logo} />
        </a>
      </Col>
      <Col xs={{ span: 16 }} span={12}>
        {/* { !['/log-in', '/sign-up'].includes(path)
          && (
          <div className={styles.ButtonsColumn}>
            <Button
              href="/log-in"
              type="primary"
            >
              <h5>{texts.authButton}</h5>
            </Button>
          </div>
          )} */}
      </Col>
    </Row>
  </div>
);

export default GuestNavBar;
