import React, { FC } from 'react';
import ApolloWrapperProvider from 'apollo/Provider';
import AppNavigation from 'routes';

const App: FC = () => (
  <ApolloWrapperProvider>
    <AppNavigation />
  </ApolloWrapperProvider>
);

export default App;
