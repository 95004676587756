/* eslint-disable @typescript-eslint/no-empty-function */
import jwtDecode from 'jwt-decode';
import userPool from 'auth/userPool';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { UserAuth } from './types';

export const DEFAULT_USER_AUTH = Object.freeze({
  userId: '',
  username: '',
  idToken: '',
  authenticated: false,
});

export const USER_AUTH_KEY = 'UserAuth';
/** Check if a token has expired
 * @param userAuth - user auth object
 */

export const cleanStorage = (): void => {
  window.localStorage.clear();
};

export const storeIDToken = (userAuth: UserAuth) => {
  window.localStorage.setItem(USER_AUTH_KEY, JSON.stringify(userAuth));
};

const cleanIDToken = () => {
  window.localStorage.setItem(USER_AUTH_KEY, JSON.stringify(DEFAULT_USER_AUTH));
};

export const getStoredUserAuth = (): UserAuth => {
  const auth = window.localStorage.getItem(USER_AUTH_KEY);
  if (auth) {
    return JSON.parse(auth) as UserAuth;
  }
  return DEFAULT_USER_AUTH;
};

export const refreshToken = (
  onSuccess: (()=>void) | undefined = () => {},
  onError: (()=>void) | undefined = () => {},
) => {
  const cognitoUser = userPool.getCurrentUser();
  const userAuth = getStoredUserAuth();
  if (!userAuth || !userAuth.idToken) {
    onError();
    return;
  }

  try {
    cognitoUser?.getSession((err: unknown, session: CognitoUserSession) => {
      if (err) {
        cleanIDToken();
        onError();
      } else {
        const cognitoRefreshToken = session.getRefreshToken();
        cognitoUser.refreshSession(
          cognitoRefreshToken,
          (_err, result: CognitoUserSession) => {
            if (_err) {
              cleanIDToken();
              onError();
            } else {
              storeIDToken({
                userId: result.getIdToken().payload.sub as string,
                idToken: result.getIdToken().getJwtToken(),
                authenticated: true,
                username: userAuth.username,
              });
              onSuccess();
            }
          },
        );
      }
    });
  } catch (error) {
    cleanIDToken();
    onError();
  }
};

export const isIDTokenValid = (): boolean => {
  const userAuth = getStoredUserAuth();
  if (!userAuth || !userAuth.idToken) {
    return false;
  }

  try {
    const decodedJwt: { exp: number } = jwtDecode(userAuth.idToken);
    if (decodedJwt.exp >= Date.now() / 1000) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};
