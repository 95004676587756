import { capitalizeFirstLetter, formatDate } from './utils';

export const listTitle = 'Mis Cupones';
export const ticket = (prize: string) => `¡Vale por ${prize}!`;
export const usedTicket = (prize: string) => `${capitalizeFirstLetter(prize)}`;
export const used = (usedAt: string) => `Canjeado el ${formatDate(new Date(usedAt))}`;

// Modal
export const modalTitle = 'Tienes un cupón!';
export const modalBody = 'Tienes un cupón asociado a esta tarjeta disponible para canjear';
