import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import {
  Form, Input, Button, Result,
} from 'antd';
import useErrorHandler from 'hooks/useErrorHandler';
import LoadingModal from 'components/generalUI/LoadingModal';
import * as texts from 'assets/texts/signUp';
import emailRegExp from 'utils/validations/emailRegExp';
import useCreateClientMutation from 'apollo/resolvers/client/create';
import { Link, useHistory, useLocation } from 'react-router-dom';
import throttle from 'lodash.throttle';
import { refreshToken } from 'auth/storage';
import styles from './SignUp.module.scss';

enum SignType {
  signIn = '/log-in',
  signUp = '/sign-up'
}

const SignUp: FC = () => {
  const history = useHistory<{ from: string }>();
  const location = useLocation();
  const pathName = location.pathname;
  const [signType, setSignType] = useState<SignType | null>(null);
  const [createClient] = useCreateClientMutation();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [isSignUpComplete, setIsSignUpComplete] = useState(false);
  const [refreshAuthLoading, setRefreshAuthLoading] = useState(true);
  const { showError } = useErrorHandler(null);

  const onError = (error: Error) => {
    setLoading(false);
    showError(error);
  };
  const onFinish = async (values: {
    email: string;
    password: string;
  }) => {
    const name = '';
    const familyName = '';
    const callbackUrl = history.location.state.from || '/';
    setLoading(true);
    const res = await createClient({
      variables: {
        name,
        familyName,
        email: values.email,
        callbackUrl,
      },
    });
    if (res) {
      const userId = res.data?.createClient.id;
      if (userId) {
        setLoading(false);
        setEmail(values.email);
        setIsSignUpComplete(true);
      } else {
        onError(new Error('Hubo un problema creando la cuenta'));
      }
    } else {
      onError(new Error('Hubo un problema en el registro'));
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledOnFinish = useMemo(() => throttle(onFinish, 30000), []);
  const { from } = history.location.state;
  const onRefreshAuthSuccess = () => { history.replace(from || ''); };
  const onRefreshAuthError = () => { setRefreshAuthLoading(false); };

  useEffect(() => {
    if (pathName === SignType.signIn) {
      setSignType(SignType.signIn);
    } else {
      setSignType(SignType.signUp);
    }
    refreshToken(onRefreshAuthSuccess, onRefreshAuthError);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    isSignUpComplete ? (
      <Result
        status="success"
        title={texts.successfullSignUp}
        subTitle={texts.successfullSignUpsubtitle(email)}
        extra={[
          <Button
            type="primary"
            key="console"
            href="tel:+56975431056"
          >
            {texts.successfullSignUpProblemsButton}
          </Button>,
        ]}
      />
    ) : (
      <div className={styles.MainContainer}>
        <LoadingModal visible={loading || refreshAuthLoading || !signType} />
        {!refreshAuthLoading && (
        <>
          <h1>{signType === SignType.signIn ? texts.titleSignIn : texts.titleSignUp}</h1>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={throttledOnFinish}
            autoComplete="off"
            className={styles.Form}
          >

            <Form.Item
              label={texts.emailLabel}
              name="email"
              rules={[
                { required: true, pattern: emailRegExp(), message: texts.emailWarning },
              ]}
              normalize={(value: string) => value.toLowerCase().trim()}
            >
              <Input />
            </Form.Item>

            <Form.Item label=" " colon={false}>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.SubmitButton}
                loading={loading}
              >
                {!loading
              && <h4>{signType === SignType.signIn ? texts.logInButton : texts.signUpButton}</h4>}
              </Button>
            </Form.Item>
            <Form.Item label=" " colon={false}>
              <p>
                {signType === SignType.signIn ? texts.signUpLabel : texts.logInLabel}
                {' '}
                <Link to={{
                  pathname: signType === SignType.signIn ? SignType.signUp : SignType.signIn,
                  state: history.location.state,
                }}
                >
                  {signType === SignType.signIn ? texts.signUpButton : texts.logInButton}
                </Link>
              </p>
            </Form.Item>
          </Form>
        </>
        )}
      </div>
    )
  );
};

export default SignUp;
