import React, { FC, ReactElement } from 'react';
import { Modal, ModalProps, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './LoadingModal.module.scss';

type Props = ModalProps;

const LoadingModal: FC<Props> = (props: Props): ReactElement => (
  <div
    role="button"
    tabIndex={0}
    onClick={(e) => e.stopPropagation()}
    onKeyPress={undefined}
  >
    <Modal
      centered
      width="auto"
      className={styles.modal}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      footer={null}
      closable={false}
    >
      <Spin indicator={<LoadingOutlined className={styles.Loader} spin />} />
    </Modal>
  </div>
);

export default LoadingModal;
