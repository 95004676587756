import React, { FC, useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Layout, Affix } from 'antd';
import { showErrorModal, errorData } from 'apollo/cache';
import NavBar from 'components/MainLayout/NavBar';
// import Footer from 'components/generalUI/MainLayout/Footer';
import ErrorModal from 'views/Error';
import { isIDTokenValid } from 'auth/storage';
import styles from './MainLayout.module.scss';
import GuestNavBar from './GuestNavBar';

interface Props {
  children?: JSX.Element;
}

const MainLayout: FC<Props> = ({ children }: Props) => {
  // const { Header, Content, Footer: FooterLayout } = Layout;
  const { Header, Content } = Layout;
  const showErrorModalHook = useReactiveVar(showErrorModal);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  useEffect(() => {
    if (showErrorModalHook) {
      setErrorModalVisible(true);
    }
  }, [showErrorModalHook]);
  const userIsValid = isIDTokenValid();

  return (
    <Layout>
      <ErrorModal
        visible={errorModalVisible}
        onClose={() => {
          setErrorModalVisible(false);
          showErrorModal(false);
          errorData(null);
        }}
      />
      <Affix offsetTop={0}>
        <Header className={styles.LayoutHeader}>
          {userIsValid ? <NavBar /> : <GuestNavBar />}
        </Header>
      </Affix>
      <Content>
        <div className={styles.MainContainer}>
          <div className={styles.App}>
            <div className={styles.ComponentContainer}>{children}</div>
          </div>
        </div>
      </Content>
      {/* <FooterLayout className={styles.LayoutFooter}>
        <Footer />
      </FooterLayout> */}
    </Layout>
  );
};

MainLayout.defaultProps = {
  children: undefined,
};

export default MainLayout;
