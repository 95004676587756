import { gql, useQuery } from '@apollo/client';
import { Card, QueryGetOrCreateCardArgs } from 'apollo/generated/types';

const GET_OR_CREATE_CARD = gql`
  query getOrCreateCard($qrCodeId: String!) {
    getOrCreateCard(qrCodeId: $qrCodeId) {
      id
      cardTemplate {
        prize
        repetitions
        icon
        company {
          name
        }
      }
      checks {
        createdAt
      }
      coupons {
        id
        usedAt
      }
    }
  }
`;

const useGetOrCreateCardQuery = (qrCodeId: string) => useQuery<
{ getOrCreateCard: Card }, QueryGetOrCreateCardArgs
>(GET_OR_CREATE_CARD, { variables: { qrCodeId } });
export default useGetOrCreateCardQuery;
