import LoadingModal from 'components/generalUI/LoadingModal';
import { FC, useState } from 'react';
import * as qrcode from 'qrcode';
import { Row, Col, Button } from 'antd';
import { useParams } from 'react-router-dom';
import useGetCouponQuery from 'apollo/resolvers/coupon/get';
import CouponTicket from 'components/coupon/CouponTicket';

const QRCode: FC<{payload: string;}> = (props: {payload: string;}) => {
  const [imageData, setImageData] = useState<string>();
  const { payload } = props;
  qrcode.toDataURL(payload, { errorCorrectionLevel: 'M' }).then((res) => setImageData(res));
  return (
    <img src={imageData} alt="QRCode" style={{ width: '20vh', marginBottom: '2vh' }} />
  );
};

interface QueryParams {
  id: string;
}

const Coupon: FC = () => {
  const { id } = useParams<QueryParams>();
  const { data, loading } = useGetCouponQuery(id);

  const couponId = data?.getCoupon.id;
  const prize = data?.getCoupon?.prize;
  const companyName = data?.getCoupon?.card.cardTemplate.company.name;
  const usedAt = data?.getCoupon.usedAt;

  return (
    <Row style={{ width: '90%', margin: 'auto' }}>
      <Col style={{ width: '100%' }} span={24}>
        <h1>Mi cupón</h1>
      </Col>
      {!loading
        ? (
          <Col style={{ width: '100%' }} span={24} md={12}>
            {prize && companyName && (
            <CouponTicket
              prize={prize}
              companyName={companyName}
              usedAt={usedAt}
              clickable={false}
            />
            )}
            {!usedAt ? (
              <>
                <h3 style={{ marginLeft: '0', marginRight: 'auto' }}>¡Canjéalo!</h3>
                <QRCode payload={
            `https://business.volbi.app/coupon/${couponId}`
            }
                />
                <p>Quien te atiende deberá escanear este QR para canjear tu cupón</p>
                {' '}
              </>
            )
              : (
                <h3>¡Esperamos que lo hayas disfrutado!</h3>
              )}
          </Col>
        )
        : <LoadingModal visible={loading} />}
      <Col style={{ width: '100%' }} span={24}>
        <Button href="/coupons" style={{ margin: '1vh' }}><h5>Todos mis cupones</h5></Button>
      </Col>
    </Row>
  );
};

export default Coupon;
