import { Row, Empty, Button } from 'antd';
import { FC } from 'react';
import * as texts from 'assets/texts/generalUI';
import styles from './styles.module.scss';

interface EmptyResultsProps {
}

const EmptyResults: FC<EmptyResultsProps> = () => (
  <Row className={styles.ContainerRow}>
    <Empty description={texts.noResultsDescription} style={{ margin: 'auto' }}>
      <Button type="primary" href="/scan-check">{texts.noResultsButton}</Button>
    </Empty>
  </Row>
);

export default EmptyResults;
