import { gql, useMutation } from '@apollo/client';
import { Client, CreateClientInput } from 'apollo/types';

const CREATE_CLIENT = gql`
  mutation createClient(
    $name: String!
    $familyName: String!
    $email: String!
    $phoneNumber: String
    $dni: String
    $companyId: String
    $callbackUrl: String
  ) {
    createClient(
      input: {
        name: $name
        familyName: $familyName
        email: $email
        phoneNumber: $phoneNumber
        dni: $dni
        companyId: $companyId
        callbackUrl: $callbackUrl
      }
    ) {
      id
      email
      name
      familyName
      phoneNumber
      dni
    }
  }
`;

const useCreateClientMutation = () => useMutation<
  { createClient: Client }, CreateClientInput
>(CREATE_CLIENT);

export default useCreateClientMutation;
