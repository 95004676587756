/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Col, Row } from 'antd';
import useListCardsQuery from 'apollo/resolvers/card/list';
import CardTicket from 'components/card/CardTicket';
import LoadingModal from 'components/generalUI/LoadingModal';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import * as texts from 'assets/texts/card';
import EmptyResults from 'components/generalUI/EmptyResults';

const Cards: FC = () => {
  const { data, loading } = useListCardsQuery();
  const history = useHistory();

  return (
    <Row style={{ width: '90%', margin: 'auto' }}>
      <Col span={24} md={12}>
        <h1>{texts.listTitle}</h1>
        { data?.listMyCards.length === 0
          ? (
            <EmptyResults />
          )
          : (
            data?.listMyCards.map((card) => {
              const companyName = card.cardTemplate.company.name;
              const { repetitions, prize, icon } = card.cardTemplate;
              const checksTotal = card.checks.length;

              return (
                <div onClick={() => { history.push(`/cards/${card.id}`); }} key={card.id}>
                  <CardTicket
                    checks={checksTotal}
                    repetitions={repetitions}
                    prize={prize}
                    companyName={companyName}
                    icon={icon}
                  />
                </div>
              );
            })
          )}
        <LoadingModal visible={loading} />
      </Col>
    </Row>
  );
};

export default Cards;
