/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Routes from './routes';
import AuthRoute from './authRoute';

const AppNavigation = () => (
  <Router>
    <Switch>
      {Routes.map((route) => <AuthRoute key={route.title} {...route} />)}
    </Switch>
  </Router>
);
export default AppNavigation;
