import { gql, useQuery } from '@apollo/client';
import { Card, QueryGetCardArgs } from 'apollo/generated/types';

const GET_CARD = gql`
  query getCard($id: String!) {
    getCard(id: $id) {
      id
      cardTemplate {
        prize
        repetitions
        icon
        company {
          name
        }
      }
      checks {
        createdAt
      }
      coupons {
        id
        usedAt
      }
    }
  }
`;

const useGetCardQuery = (id: string) => useQuery<
{ getCard: Card }, QueryGetCardArgs
>(GET_CARD, { variables: { id } });
export default useGetCardQuery;
