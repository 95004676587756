export const rewardDescription = 'El simple camino a tu';
export const checksAmountLabel = (repetitions = 6) => `Recíbelo al juntar 
${repetitions} timbres`;
export const prizeDescription = (prize = '') => {
  const firstWordArr = prize.split(' ');
  let formatedPrize = prize;
  if (firstWordArr.length) {
    if (['UN', 'UNA', '1'].includes(firstWordArr[0].toUpperCase())) {
      formatedPrize = firstWordArr.slice(1).join(' ');
    }
  }
  return formatedPrize;
};
export const checksAmountSubtitle = 'Suma uno en cada visita';
export const goToCarBtnLabel = 'Ir a la carta';
export const signUpBtnLabel = 'Empieza a sumar timbres';
