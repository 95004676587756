import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { storeIDToken } from 'auth/storage';
import userPool from 'auth/userPool';

const login = async (
  email: string,
  answer: string,
  onSuccessFunction?: () => void,
  onLoadingFunction?: () => void,
  onErrorFunction?: (error: Error) => void,
) => {
  const authData = {
    Username: email,
  };
  const authDetails = new AuthenticationDetails(authData);
  const userData = {
    Username: email,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');
  cognitoUser.initiateAuth(authDetails, {
    onSuccess(result: CognitoUserSession) {
      storeIDToken({
        userId: result.getIdToken().payload.sub as string,
        idToken: result.getIdToken().getJwtToken(),
        authenticated: true,
        username: email,
      });
      // User authentication was successful
      if (onSuccessFunction) onSuccessFunction();
    },
    onFailure(error) {
      // User authentication was not successful
      if (onErrorFunction) onErrorFunction(error);
    },
    customChallenge() {
      // User authentication depends on challenge response
      cognitoUser.sendCustomChallengeAnswer(answer, this);
    },
  });
};

export default login;
