import React, { FC } from 'react';
import {
  Row, Col, Modal, Button,
} from 'antd';
import { useReactiveVar } from '@apollo/client';
import { errorData } from 'apollo/cache';
import { useHistory } from 'react-router-dom';
import styles from './Error.module.scss';
import * as texts from '../../assets/texts/error';

interface ErrorModalProps {
  visible?: boolean;
  onClose: () => void;
}

const ErrorModal: FC<ErrorModalProps> = ({ visible, onClose }: ErrorModalProps) => {
  const data = useReactiveVar(errorData);
  const history = useHistory();
  return (
    data ? (
      <Modal
        centered
        visible={visible}
        onOk={onClose}
        onCancel={onClose}
        width="100%"
        className={styles.ErrorModal}
        footer={null}
      >
        <div className={styles.DecorationBar} />
        <Row justify="center" className={styles.ErrorContainer}>
          <Row justify="start" align="middle" className={styles.TitleContainer}>
            <Col
              xs={{ span: 4, offset: 10 }}
              md={{ span: 3, offset: 3 }}
              lg={{ span: 2, offset: 3 }}
              xl={{ span: 2, offset: 3 }}
            >
              <div className={styles.ErrorIcon}>!</div>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 14, offset: 1 }}
              lg={{ span: 16, offset: 0 }}
              xl={{ span: 15, offset: 1 }}
            >
              <Row className={styles.ModalTitle}>{texts.title}</Row>
              <Row className={styles.ModalSubTitle}>{texts.subTitle}</Row>
            </Col>
          </Row>
          <Row className={styles.ErrorInfo}>
            <Col style={{ width: '100%' }}>
              <Row justify="start" className={styles.ErrorMessageContainer}>
                <Col xs={{ span: 7 }} md={{ span: 4, offset: 3 }}>
                  {texts.errorLabel}
                </Col>
                <Col xs={{ span: 17 }} md={{ span: 14 }} className={styles.ErrorCode}>
                  {data.code || ''}
                </Col>
              </Row>
              <Row justify="start" className={styles.ErrorMessageContainer}>
                <Col xs={{ span: 7 }} md={{ span: 4, offset: 3 }}>
                  {texts.messageLabel}
                </Col>
                <Col xs={{ span: 17 }} md={{ span: 14 }} className={styles.ErrorCode}>
                  {data.message || ''}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify="start" className={styles.Contact}>
            <Col md={{ span: 18, offset: 3 }}>
              <Row justify="start" className={styles.ContactParagraph}>
                <p>{texts.contactTextStart}</p>
              </Row>
              <Row justify="start" className={styles.ContactParagraph}>
                <p>
                  {texts.inLabel}
                  <span className={styles.ContactBold}>{texts.companyName}</span>
                  {texts.companyText}
                  <a
                    href={`mailto:${texts.contactMail}?subject=Error ${data.code}`}
                    className={styles.ContactBold}
                  >
                    {texts.contactMail}
                  </a>
                  {texts.contactTextEnd}
                </p>
              </Row>
            </Col>
          </Row>
          <Row justify="center" className={styles.ButtonContainer}>
            <Button
              onClick={() => {
                onClose();
                history.replace('/');
              }}
            >
              {texts.redirectText}
            </Button>
          </Row>
        </Row>
      </Modal>
    ) : null
  );
};

ErrorModal.defaultProps = {
  visible: false,
};

export default ErrorModal;
