import React, { FC } from 'react';
import { QrReader } from '@blackbox-vision/react-qr-reader';
import * as texts from 'assets/texts/QRScanner';

interface Props {
  header?: string | null;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const QRScanner: FC<Props> = (props: Props) => {
  const { header } = props;
  return (
    <>
      { header !== null && <h2 style={{ margin: '3vh 0 0 0' }}>{header}</h2>}
      <QrReader
        containerStyle={{ width: '100%', margin: 'auto' }}
        scanDelay={200}
        onResult={(result, error) => {
          if (result) {
            const url = new URL(result.getText());
            window.location.replace(url.pathname);
          }
          if (error) {
            // eslint-disable-next-line no-console
            // console.info(error);
          }
        }}
        constraints={{ facingMode: 'environment' }}
      />
      <p>{texts.noQR}</p>
    </>
  );
};

QRScanner.defaultProps = {
  header: texts.header,
};

export default QRScanner;
