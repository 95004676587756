import React, { FC } from 'react';
import ReviLogo from 'assets/images/logo.png';
import { Button, Col, Row } from 'antd';
// import * as texts from 'assets/texts/navBar';
import styles from './NavBar.module.scss';

const NavBar: FC = () => {
  const path = window.location.pathname;
  return (
    <div>
      <Row justify="space-around" className={styles.Container}>
        <Col xs={{ span: 10 }} span={16}>
          <a href="/">
            <img src={ReviLogo} alt="Volbi Logo" className={styles.Logo} />
          </a>
        </Col>
        <Col xs={{ span: 14 }} span={8}>
          <div className={styles.ButtonsColumn}>
            {path.substring(0, 5) !== '/card' && (
            <Button
              href="/cards"
              className="dark"
            >
              <h5>Mis tarjetas</h5>
            </Button>
            )}
            {path.substring(0, 5) === '/card' && (
              <Button
                href="/coupons"
                className="dark"
              >
                <h5>Mis cupones</h5>
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default NavBar;
