import React, { FC, useEffect, useState } from 'react';
import {
  Button, Col, Image, Row,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import * as texts from 'assets/texts/menu';
import Checks from 'components/card/Checks';
import { cleanStorage, isIDTokenValid, refreshToken } from 'auth/storage';
import { useGetCompanyLazyQuery } from 'apollo/resolvers/company/get';
import LoadingModal from 'components/generalUI/LoadingModal';
import styles from './Menu.module.scss';

interface QueryParams {
  companyId: string;
}

interface CompanyData {
  prize: string,
  repetitions: number,
  icon: string,
  qrCodeId?: string,
  companyMenuUrl?: string | null,
  companyLogoUrl?: string | null
}

const Menu: FC = () => {
  const { companyId } = useParams<QueryParams>();
  const [refreshAuthLoading, setRefreshAuthLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const history = useHistory<{ from: string }>();
  const userIsValid = isIDTokenValid();
  const [getCompany] = useGetCompanyLazyQuery(companyId);
  const [company, setCompany] = useState<CompanyData | null>({
    prize: '',
    repetitions: 6,
    icon: 'SMILE',
  });
  const activeChecks = [1];

  const onGoToSignUp = () => {
    history.push(`/card/${company?.qrCodeId}`);
  };

  const fetchCompanyData = async () => {
    setLoading(true);
    const companyRes = await getCompany();
    if (companyRes) {
      const companyData = companyRes.data?.getCompany;
      const cardTemplate = companyData?.cardTemplates.at(-1);

      setCompany({
        prize: cardTemplate?.prize || '',
        repetitions: cardTemplate?.repetitions || 6,
        icon: cardTemplate?.icon || 'SMILE',
        qrCodeId: cardTemplate?.QRCode?.id,
        companyMenuUrl: companyData?.menuUrl,
        companyLogoUrl: companyData?.logoUrl,
      });
    }
  };

  const onRefreshAuthSuccess = async () => {
    await fetchCompanyData();
    setRefreshAuthLoading(false);
  };
  const onRefreshAuthError = async () => {
    cleanStorage();
    await fetchCompanyData();
    setRefreshAuthLoading(false);
  };

  useEffect(() => {
    if (userIsValid) {
      if (company?.companyMenuUrl) {
        window.location.replace(`${company.companyMenuUrl}`);
      } else if (company?.qrCodeId) {
        history.push(`/card/${company.qrCodeId}`);
      }
    }
    setLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  useEffect(() => {
    setRefreshAuthLoading(true);
    refreshToken(onRefreshAuthSuccess, onRefreshAuthError);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Col>
      {
      (!loading && !refreshAuthLoading) ? (
        <>
          <Row style={{ justifyContent: 'center', margin: '30px 0 30px 0' }}>
            {
            company?.companyLogoUrl && (
              <Image
                width={80}
                src={company.companyLogoUrl}
              />
            )
          }
          </Row>
          <Row className={styles.RewardContainer}>
            <div className={styles.InnerContainer}>
              <p className={styles.PrimaryLargeText} style={{ marginBottom: 0 }}>
                {texts.rewardDescription}
              </p>
              <p className={styles.RewardText} style={{ marginBottom: 0 }}>
                {texts.prizeDescription(company?.prize)}

              </p>
            </div>
          </Row>
          <Row style={{ justifyContent: 'center', marginTop: '30px' }}>
            <p
              className={styles.PrimaryLargeText}
              style={{ fontSize: 'large', margin: '10px 0 0 0' }}
            >
              {texts.checksAmountLabel(company?.repetitions)}

            </p>
          </Row>
          <Row style={{ justifyContent: 'center', margin: 0 }}>
            {
              company?.repetitions && (
                <Checks
                  iconSize="30px"
                  repetitions={company.repetitions}
                  checks={activeChecks || []}
                  icon={company?.icon}
                />
              )
}
          </Row>
          <Row style={{ justifyContent: 'center', margin: 0 }}>
            <p className={styles.PrimaryLargeText} style={{ fontSize: 'large' }}>
              {texts.checksAmountSubtitle}
            </p>
          </Row>
          <Col style={{ alignItems: 'center', margin: '3vh 0' }}>
            {
            company?.companyMenuUrl && (
              <Row style={{ justifyContent: 'center', marginBottom: '30px' }}>
                <Button
                  href={company.companyMenuUrl}
                >
                  {texts.goToCarBtnLabel}
                </Button>
              </Row>
            )
          }
            <Row style={{ justifyContent: 'center', marginBottom: '30px' }}>
              <Button
                type="primary"
                onClick={() => { onGoToSignUp(); }}
              >
                {texts.signUpBtnLabel}
              </Button>
            </Row>
          </Col>
        </>
      ) : (
        <LoadingModal visible={loading} />
      )
    }
    </Col>
  );
};

export default Menu;
